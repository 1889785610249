var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("auth-form", {
    attrs: { "is-login": "", action: "Login", route: "login" },
    on: { success: _vm.init }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }